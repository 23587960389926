import { serverTimestamp } from 'firebase/firestore';
import { Routes } from '../../config/constants';
import { auth } from "../../config/firebase/firebase";
import { getDocumentById } from "../../models";
import { getUserByEmail } from "../../models/users";
import { updateMainEventsDetails } from '../../models/MainEvents/mainEvents';

function getRedirectionPath(router, mainEventDetails, user) {
  const signupRoutes = [
    Routes.LOGIN,
    "/Authentication/onboarding",
    Routes.VERIFY_EMAIL,
    Routes.SUBSCRIPTION_PLANS,
    "/Subscriptions/Payment",
    Routes.CREATE_PROFILE,
    Routes.VERIFY_MOBILE,
    Routes.INVITE_PARTNER,
    "/Onboarding",
  ];

  const signUpRoute = signupRoutes.includes(router.pathname);
  const signUpPending = !mainEventDetails?.quizCompletion?.levelOne || !user?.phoneNumberVerified;
  let path = "";
  if (signUpPending) {
    if (
      mainEventDetails?.signup_with === "email" &&
      !user?.emailVerified &&
      user.type === "primary"
    ) {
      path = Routes.VERIFY_EMAIL;
    } else if (!mainEventDetails?.signup_plan_selection) {
      path = Routes.SUBSCRIPTION_PLANS;
    } else if (!mainEventDetails?.signup_plan_description) {
      path = mainEventDetails.subscriptionConformPageUrl;
    } else if (!mainEventDetails?.signup_create_profile) {
      if (router.asPath.startsWith("/Subscriptions/Payment?mode=")) { // payment success/failure redirection
        path = router.asPath;
      } else {
        path = Routes.CREATE_PROFILE;
      }
    } else if (!user?.phoneNumberVerified) {
      path = Routes.VERIFY_MOBILE;
    } else if (!mainEventDetails?.signup_partner_invited) {
      path = Routes.INVITE_PARTNER;
    } else {
      path = `/Onboarding?redirectTo=${Routes.DASHBOARD}`;
    }
  } else if (signUpRoute || router.pathname === "/") {
    path = Routes.DASHBOARD;
  }
  return path;
}

export async function checkSignUpCompleteAndRedirect(router) {
  if (!auth.currentUser) {
    return;
  }

  try {

    let loggedInUser = await getUserByEmail(auth.currentUser.email);
    let mainEventDetails = {};

    if (loggedInUser?.mainEventId) {
      mainEventDetails = await getDocumentById(
        "MainEvents",
        loggedInUser?.mainEventId
      );
    }

    if (!mainEventDetails.id) {
      console.log("❌ main event details not found");
      return;
    }

    // update last login/active date
    updateMainEventsDetails(
      { activeAt: serverTimestamp() },
      mainEventDetails.id
    );

    const path = getRedirectionPath(router, mainEventDetails, loggedInUser);
    const routePath = router.pathname === "/" ? "none" : router.pathname;
    if (path && !path.startsWith(routePath)) {
      console.log(`Redirecting 👉 ${path}`);
      return router.push(path);
    }

  } catch (error) {
    console.log("❌ ERROR ", error);
  }
}
