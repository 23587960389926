import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

import { storage } from "../../config/firebase/firebase";

export async function uploadImageToStorage(imageBlob) {
  if (!imageBlob) {
   console.error("No image provided");
   return ''
  }

  const mainEventId = JSON.parse(localStorage.getItem("userData"))?.MainEventId;
  if (!mainEventId) {
    throw new Error("No mainEventId provided");
  }

  const imageId = uuidv4();
  const storageRef = ref(
    storage,
    `public/wedding-website/${mainEventId}/${imageId}.${
      imageBlob.type.split("/")[1]
    }`
  );

  const metadata = {
    contentType: imageBlob.type,
  };

  try {
    const uploadTask = uploadBytesResumable(storageRef, imageBlob, metadata);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        error => {
          console.error("Error uploading image:", error);
          reject(
            new Error(
              `Failed to upload image: ${error.code} - ${error.message}`
            )
          );
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(
              "Image uploaded successfully. Download URL:",
              downloadURL
            );
            resolve(downloadURL);
          } catch (error) {
            console.error("Error getting download URL:", error.message);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error("Error initiating upload:", error.message);
    throw error;
  }
}

export async function processImage(image) {
  if (typeof image === 'string') {
    return image;
  }

  if (!image) {
    return ""
  }

  return uploadImageToStorage(image);
}