import dayjs from "dayjs";

import { UsDateFormat } from "../config/constants";

export function getWeddingDate(date) {
  if (!date) {
    return "";
  }
  if (date.seconds && !isNaN(date.seconds)) {
    const formattedDate = dayjs.unix(date.seconds).format(UsDateFormat);
    return dayjs(formattedDate, UsDateFormat).isValid() ? formattedDate : "";
  }
  if (dayjs(date).isValid()) {
    return dayjs(date).format(UsDateFormat);
  }
}