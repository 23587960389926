import PropTypes from "prop-types";

function ButtonLoader(props) {
  return (
    <div className="circleLoader">
      <div
        className={`cirecleLoadericon ${
          props.coloredBorder ? "orangeBorder" : ""
        }`}
      ></div>
    </div>
  );
}

ButtonLoader.propTypes = {
  coloredBorder: PropTypes.bool,
};

export default ButtonLoader;
