/**
 * Permission Wrapper
 *  - a higher order component to check for permission on accessing module paths
 *  - if user has an expired/cancelled subscription display blocking screen to resubscribe
 *  - if user has no access to module show message reflecting the same
 * @author Emvigo Technologies
 */
import React, { useEffect, useState } from "react";
import { useAuth } from "./index";
import { getPrivateModuleByRoute, isFreeModule, isPublicRoute } from "./helperFunctions";
import { useRouter } from "next/router";
import PermissionDenied from "../Subscription/PermissionDenied";
import UpgradeRequired from "../Subscription/UpgradeRequired";


const PermissionWrapper = ({ children }) => {
    const { pathname } = useRouter()
    const { user, permissions, mainEvent, isLoading } = useAuth();
    const [hasAccess, setAccess] = useState(true)
    const subscriptionStatus = mainEvent?.subscription?.status || "active"
    const isPublicPath = isPublicRoute(pathname)
    // Following useEffect code block retrieves user profile data & permissions
    useEffect(() => {
        if (isPublicPath) {
            return
        }
        const moduleInfo = getPrivateModuleByRoute(`/${pathname.split('/')[1]}`)
        if (moduleInfo) {
            const hasPermission = permissions?.[moduleInfo.requiredPermission] || false
            setAccess(hasPermission)
        } else {
            setAccess(true)
        }
    }, [pathname, user, mainEvent])
    if (user && !isLoading) {
        if (isFreeModule(pathname)) {
            return children
        }
        if (!hasAccess) {
            return <PermissionDenied />;
        }
        if (subscriptionStatus !== "active" && !isPublicPath) {
            return <UpgradeRequired />;
        }
    }

    return children
};


export default PermissionWrapper