import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../config/firebase/firebase";

/**
 *
 * @param {*} collectionName | The name of the collection
 * @returns Promise<Array> | A promise that resolves to array of active documents in the collection with collectionName
 */
export const getAllActiveFromCollection = async (collectionName) => {
    try {
        let collectionRef = collection(db, collectionName)
        let collectionQuery;
        if (collectionName === "States" || collectionName === "Religions") {
            collectionQuery = query(collectionRef, where('status', '==', 'active'), orderBy("name", "asc"))
        } else {
            collectionQuery = query(collectionRef, where('status', '==', 'active'))
        }
        query(collectionRef, where('status', '==', 'active'))
        let collectionData = await getDocs(collectionQuery);
        return collectionData.docs.map(doc => { return { ...doc.data(), id: doc.id } })
    } catch (error) {
        return []
    }
}

export const getDocumentById = async (collectionName, id, ...restPath) => {
    try {
        if (!id) {
            console.error(`❌ getDocumentById - missing id - collectino: "${collectionName}"`, {id, restPath})
            return {}
        }

        const docRef = doc(db, collectionName, id, ...restPath);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { ...docSnap.data(), id: docSnap.id };
        } else {
            return {};
        }
    } catch (error) {
        console.log('❌ ', error, {collectionName, id, restPath})
        return {};
    }
}

export const addSubCollection = async (collectionName, docId, subCollName, subCollData, subDocId, merge = true) => {
    if (docId && subCollData) {
        const subCollectionPath = [db, collectionName, docId, subCollName]
        if (subDocId) {
            await setDoc(doc(...subCollectionPath, subDocId), subCollData, { merge });
        } else {
            await addDoc(collection(...subCollectionPath), subCollData);
        }
        return true;
    } else {
        console.log('❌ ', error?.message, error)
        return false;
    }
}

export const getSubCollectionById = async ({ collectionName, mainId, subCollectionName, subId }) => {
    try {
        const docRef = doc(db, collectionName, mainId, subCollectionName, subId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { ...docSnap.data(), id: docSnap.id };
        } else {
            return {};
        }
    } catch (error) {
        return {};

    }

}
export const getDocumentsById = async (collectionName, id, ...restPath) => {
    try {
        const subColRef = collection(db, collectionName, id, ...restPath);
        const docSnap = await getDocs(subColRef);
        if (docSnap.empty) {
          return [];
        }
        else{
            return docSnap.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
        }
    } catch (error) {
        console.log("errr",error)
        return [];
    }
}

export const updateMyEventDetails = async (collectionName, subCollName, subCollData, docId, subCollId) => {
    try {
        const events = doc(db, collectionName, docId, subCollName, subCollId);
        await updateDoc(events, subCollData);
        return true;
    } catch (error) {
        return error;
    }
}

export const getDataFromCollection = async (collectionName,conditon,data) => {
    try {
        let collectionRef = collection(db, collectionName)
        let collectionQuery = query(collectionRef, where('status', '==', 'active'),where(conditon, '==', data))
        let collectionData = await getDocs(collectionQuery);
        return collectionData.docs.map(doc => { return { ...doc.data(), id: doc.id } })
    } catch (error) {
        return []
    }
}

export const upsertDocument = async (data, collectionName, docId, ...path) => {
    try {
        const docRef = doc(db, collectionName, docId, ...path);
        await setDoc(docRef, data, { merge: true });
        return true;
    } catch (error) {
        console.log('❌ ', error)
        return false
    }
}

export async function getDocumentsByCollection(collectionName) {
  const collectionRef = collection(db, collectionName);
  const collectionData = await getDocs(collectionRef);
  return collectionData.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getDocumentsByKeyAndValue(collectionName, key, value) {
  const collectionRef = collection(db, collectionName);
  const collectionQuery = query(collectionRef, where(key, '==', value));
  const collectionData = await getDocs(collectionQuery);
  return collectionData.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }));
}