import dayjs from "dayjs";
import { getWeddingWebsiteInfo } from "./weddingWebsiteTemplates";

export function formattedDateTime(date) {
  const dayjsTime = dayjs.unix(date.seconds);
  const formattedTime = dayjsTime.format('hh:mm A');
  const formattedDate = dayjsTime.format('MM/DD/YY');
  return { formattedTime, formattedDate };
}

export function formatEventDateTime(startDate, endDate) {
  if (!startDate) return { date: '', startTime: '', endTime: '' };

  const { formattedDate, formattedTime: startTime } = formattedDateTime(startDate);
  const endTime = endDate ? formattedDateTime(endDate).formattedTime : '';

  return { date: formattedDate, startTime, endTime };
}

export function formatLocation(state, city) {
  return state && city ? `${city}, ${state}` : '';
}

export async function getHomeData(user) {
  const weddingWebsiteInfo = await getWeddingWebsiteInfo();

  const savedHome = weddingWebsiteInfo?.home ?? {};

  const receptionEvent = user?.MainEventDetails?.MyEvents?.find(
    event => event.eventName === "Reception"
  );

  const home = {
    eventName: receptionEvent?.eventName ?? "",
    ...formatEventDateTime(receptionEvent?.eventDate, receptionEvent?.toDate),
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    partnerFirstName: user?.partnerFirstName ?? "",
    partnerLastName: user?.partnerLastName ?? "",
    weddingDate: receptionEvent?.eventDate ?? null,
    weddingLocation: formatLocation(receptionEvent?.state, receptionEvent?.city),
    ...savedHome,

  };
  return {home, weddingWebsiteInfo};
}

export function getCustomPageName(home) {
  const { firstName = "", partnerFirstName = "" } = home ?? {};

  if (!firstName && !partnerFirstName) return "";

  const names = [firstName, partnerFirstName].filter(Boolean);
  return names.join("-").replace(/\s+/g, "-").toLowerCase();
}
