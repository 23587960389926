import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { Provider } from "react-redux";

import { AuthProvider } from "../components/AuthContext";
import AuthWrapper from "../components/AuthContext/AuthWrapper";
import PermissionWrapper from "../components/AuthContext/PermissionWrapper";
import { analytics } from "../config/firebase/firebase";
import { ChatProvider } from "../hooks/useConnectUser";
import { getPublishedWeddingWebsite } from "../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import { checkSignUpCompleteAndRedirect } from "../modules/auth/onBoard";
import store from "../store/store";
import "../styles/builder.scss";
import "../styles/style.scss";
import Splash from "./Authentication/splash";

function MyApp({ Component, pageProps }) {
  const [loadingPage, setLoading] = useState(true);
  const [oneSignalInit, setOneSignalInit] = useState(false);
  const router = useRouter();

  // load wedding website without login
  const onWeddingWebsite = [
    "/[weddingWebsiteCustomPageName]/[pageId]",
    "/[weddingWebsiteCustomPageName]/custom",
  ].includes(router.pathname);
  const onTemplateDemo =
    router.pathname === "/wedding-templates/demo/[templateId]/[pageId]";

  useEffect(() => {
    if (!onWeddingWebsite && !onTemplateDemo) {
      checkSignUpCompleteAndRedirect(router);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const inIframe = window.self !== window.top;
    if (onWeddingWebsite || inIframe || onTemplateDemo) {
      /**
       * no need to integrate one signal as guests will not get anynotification
       *
       * in iframe we are showing wedding website preview
       * where we don't need to show a alert for allowing one signal notification
       */
      return;
    }

    window.OneSignal = window.OneSignal || [];
    if (oneSignalInit || !window?.OneSignal) return;

    if (!process.env.NEXT_PUBLIC_ONESIGNAL_APPID) {
      throw new Error("Set NEXT_PUBLIC_ONESIGNAL_APPID env variable");
    }

    if (!process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID) {
      throw new Error("Set NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID env variable");
    }

    if (typeof window?.OneSignal?.init === "function") {
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APPID,
        safari_web_id: process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID,
        notifyButton: {
          enable: false,
        },
        serviceWorkerPath: "./sw.js",
        allowLocalhostAsSecureOrigin: true,
      })
        .then(async () => {
          await OneSignal.showSlidedownPrompt();
          setOneSignalInit(true);

          OneSignal.on("subscriptionChange", function (isSubscribed) {
            console.log("The user's subscription state is now:", isSubscribed);
          });
        })
        .catch(err => {
          console.error("❌ OneSignal - error initializing one signal", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneSignalInit]);

  async function init() {
    analytics();

    if (onWeddingWebsite) {
      const customPageName = router.query.weddingWebsiteCustomPageName;
      const weddingWebsite = await getPublishedWeddingWebsite(customPageName);

      const userData = {
        MainEventId: weddingWebsite.mainEventId,
        onWeddingWebsite,
        onTemplateDemo,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    } else if (onTemplateDemo) {
      const templateId = router.query.templateId;
      const pageId = router.query.pageId;
      const templateData = { templateId, pageId, onTemplateDemo };
      localStorage.setItem(
        "wedding-website-demo",
        JSON.stringify(templateData)
      );
    } else {
      localStorage.removeItem("wedding-website-demo");
    }

    setLoading(false);
    window.addEventListener("pointermove", handleScroll);
    window.addEventListener("touchmove", handleScroll);

    return () => {
      window.removeEventListener("pointermove", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
      window.OneSignal = undefined;
    };
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    if (document.querySelector(".overlay")) {
      document.body.style.overflow = "hidden";
    } else if (document.body.style.overflow !== "auto") {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <>
      <Head>
        <title>DesiWeds</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, user-scalable=0, viewport-fit=cover"
        />
      </Head>
      {loadingPage ? (
        <Splash />
      ) : (
        <Provider store={store}>
          {onWeddingWebsite || onTemplateDemo ? (
            <Component {...pageProps} />
          ) : (
            <AuthProvider>
              <AuthWrapper>
                <PermissionWrapper>
                  <ChatProvider>
                    <Component {...pageProps} />
                  </ChatProvider>
                </PermissionWrapper>
              </AuthWrapper>
            </AuthProvider>
          )}
        </Provider>
      )}
    </>
  );
}

export default MyApp;
